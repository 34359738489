import { Box, Grid, IconButton, Link, Skeleton, SxProps, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Typography } from "@mui/material";
import { Map as MapIcon, Call as CallIcon, Schedule as ScheduleIcon, Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import React, { useEffect } from "react";
import { IGeneralInfo } from "Models/GeneralInfo";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";

const daysOfWeek = [
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
    'Zondag',
]

export default function Footer() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [generalInfo, setGeneralInfo] = React.useState<IGeneralInfo>();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetGeneralInfo();
        setGeneralInfo(result);
        setIsLoading(false);
    }

    const iconProps: SxProps<Theme> = {
        height: 36,
        width: 36,
        color: 'white',
    }

    const socialIconProps: SxProps<Theme> = {
        color: 'white',
    }

    return(
        <Grid container spacing={4} mt={4} justifyContent='center' sx={{backgroundColor: theme => theme.palette.primary.main}} pb={2}>
            <Grid item xs={12} sm={8} md={6} lg={4} justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                <Box sx={iconProps} component={MapIcon} />
                <Typography variant="body1" fontSize={12} fontWeight={500} color='white'>Adres</Typography>
                <Box component={Link} href="https://goo.gl/maps/d4pkATDyHTrnm4DQ8" target="_blank">
                    <Typography variant="body1" fontSize={24} fontWeight={500} color='white'>Kijckerweg 22</Typography>
                    <Typography variant="body1" fontSize={24} fontWeight={500} color='white'>2678AD</Typography>
                    <Typography variant="body1" fontSize={24} fontWeight={500} color='white'>De Lier</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={4} justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                <Box sx={iconProps} component={CallIcon} />
                <Typography variant="body1" fontSize={12} fontWeight={500} color='white'>Contact</Typography>
                <Box>
                    <br/>
                    <Typography variant="body1" fontSize={12} fontWeight={500} color='white'>Telefoon</Typography>
                    <Box component={Link} href="tel:0174 510 935" target="_blank"><Typography variant="body1" fontSize={18} fontWeight={500} color='white'>0174 510 935</Typography></Box>
                    <br/>
                    <Typography variant="body1" fontSize={12} fontWeight={500} color='white'>Email</Typography>
                    <Box component={Link} href="mailto:filmclubdelier@gmail.com" target="_blank"><Typography variant="body1" fontSize={18} fontWeight={500} color='white'>filmclubdelier@gmail.com</Typography></Box>
                    <Box>
                        <IconButton href="https://www.facebook.com/FilmclubDeLier" sx={socialIconProps} target="_blank"><Facebook /></IconButton>
                        <IconButton href="https://www.instagram.com/speelgoed_avondwinkel_delier/" sx={socialIconProps} target="_blank"><Instagram /></IconButton>
                        <IconButton href="https://wa.me/31642145895" sx={socialIconProps} target="_blank"><WhatsApp /></IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={4} justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                <Box sx={iconProps} component={ScheduleIcon} />
                <Typography variant="body1" fontSize={12} fontWeight={500} color='white'>Openingstijden</Typography>
                <Box component={TableContainer} maxWidth={300}>
                    <Table size='small'>
                        <TableBody>
                            {daysOfWeek.map((d, i) => 
                                <TableRow key={i}>
                                    <Box component={TableCell} lineHeight={1} color='white' sx={{borderBottom: 'none'}}>{d}</Box>
                                    <Box component={TableCell} lineHeight={1} align="right" color='white' display='flex' sx={{borderBottom: 'none'}}>
                                        {generalInfo?.openingHours[i] ?? <Skeleton width={40} />} - {generalInfo?.closingHours[i] ?? <Skeleton width={40} />}
                                    </Box>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </Grid>
            <Grid xs={12}><Typography sx={{ color: 'white', mt: 1 }} fontSize={12}>Gesponsord door <a target="_blank" href="https://www.voertuigvinder.nl">voertuigvinder.nl</a></Typography></Grid>
        </Grid>
    );
}